var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refFormObserver"},[_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('label',{staticClass:"mr-5",attrs:{"for":"is_enabled"}},[_vm._v("Habilitado para Consultas ")]),_c('b-form-checkbox',{attrs:{"id":"is_enabled","checked":true,"switch":""},model:{value:(_vm.formData.is_enabled),callback:function ($$v) {_vm.$set(_vm.formData, "is_enabled", $$v)},expression:"formData.is_enabled"}})],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"lg":"5"}}),_c('b-col',{attrs:{"lg":"7"}},[_c('b-form-group',{attrs:{"label":"Centro de Salud"}},[_c('validation-provider',{attrs:{"name":"Centro de Salud","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.medicalCenters,"clearable":true,"reduce":function (item) { return item.id; },"label":"name"},model:{value:(_vm.formData.medical_center_id),callback:function ($$v) {_vm.$set(_vm.formData, "medical_center_id", $$v)},expression:"formData.medical_center_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Código"}},[_c('validation-provider',{attrs:{"name":"Código","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nombre"}},[_c('validation-provider',{attrs:{"name":"Nombre del consultorio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"lg":"5"}}),_c('b-col',{attrs:{"lg":"7"}},[_c('b-form-group',{attrs:{"label":"Tipo de Consultorio"}},[_c('validation-provider',{attrs:{"name":"Tipo de Consultorio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.unitTypes,"clearable":true,"reduce":function (item) { return item.id; },"label":"name"},model:{value:(_vm.formData.unit_type_id),callback:function ($$v) {_vm.$set(_vm.formData, "unit_type_id", $$v)},expression:"formData.unit_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Especialidad"}},[_c('validation-provider',{attrs:{"name":"Especialidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.filterSpecialties,"clearable":true,"reduce":function (item) { return item.id; },"label":"name"},model:{value:(_vm.formData.specialty_type_id),callback:function ($$v) {_vm.$set(_vm.formData, "specialty_type_id", $$v)},expression:"formData.specialty_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Horario de Atención"}},[_c('validation-provider',{attrs:{"name":"Horario de Atención","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.serviceHour,"clearable":true,"reduce":function (item) { return item.id; },"label":"name"},model:{value:(_vm.formData.service_hour_id),callback:function ($$v) {_vm.$set(_vm.formData, "service_hour_id", $$v)},expression:"formData.service_hour_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Duración consulta"}},[_c('validation-provider',{attrs:{"name":"Duración","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":[10,15,20,25,30,35,40,45,50,55]},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.label)+" minutos ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.label)+" minutos ")]}}],null,true),model:{value:(_vm.formData.duration_time),callback:function ($$v) {_vm.$set(_vm.formData, "duration_time", $$v)},expression:"formData.duration_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Reservar con anticipación de (solo para el asegurado)"}},[_c('validation-provider',{attrs:{"name":"Reservacion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":[1,5,10,20,30,60,90]},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.label)+" dias ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.label)+" dias ")]}}],null,true),model:{value:(_vm.formData.advance_booking_days),callback:function ($$v) {_vm.$set(_vm.formData, "advance_booking_days", $$v)},expression:"formData.advance_booking_days"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('hr'),_c('MedicalUnitUsersList',{ref:"refUsersList"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }