var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{attrs:{"align-v":"center","align-h":"between"}},[_c('b-col',{staticClass:"my-1"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Personal Consultorio")])]),_c('b-col',{staticClass:"my-1",attrs:{"md":"4"}},[_c('b-button',{attrs:{"variant":"flat-primary","block":""},on:{"click":function($event){return _vm.addItem()}}},[_vm._v(" Agregar Personal")])],1)],1),_c('validation-observer',{ref:"refFormObserver"},[_c('div',{staticClass:"table-responsive",staticStyle:{"padding-bottom":"150px"}},[_c('table',{staticClass:"table table-sm table-bordered"},[_c('thead',[_c('tr',[_c('th'),_c('th',{attrs:{"width":"50%"}},[_vm._v("NOMBRE")]),_c('th',{attrs:{"width":"40%"}},[_vm._v("ROL")])])]),_c('tbody',[_vm._l((_vm.formData.users),function(item,index){return _c('tr',{key:index},[_c('td',{attrs:{"align":"center"}},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1),_c('td',[_c('validation-provider',{attrs:{"vid":("user_" + index),"name":"Usuario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"fullname","clearable":false,"reduce":function (record) { return record.id; },"options":_vm.users,"selectable":function (option) { return !_vm.selectedUsers.includes(option.id); }},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":errors.length ? false : null}},'input',attributes,false),events))]}}],null,true),model:{value:(item.user_id),callback:function ($$v) {_vm.$set(item, "user_id", $$v)},expression:"item.user_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('td',[_c('validation-provider',{attrs:{"vid":("role_" + index),"name":"Rol","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"display_name","clearable":false,"options":_vm.getRoles(item.user_id, index),"reduce":function (record) { return record.id; }},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":errors.length ? false : null}},'input',attributes,false),events))]}}],null,true),model:{value:(item.role_id),callback:function ($$v) {_vm.$set(item, "role_id", $$v)},expression:"item.role_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])}),(_vm.formData.users.length === 0)?_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('p',{staticClass:"text-center my-5"},[_vm._v(" El consultorio aun no tiene Personal registrado. "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addItem.apply(null, arguments)}}},[_vm._v("Agregar Personal.")])])])]):_vm._e()],2)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }